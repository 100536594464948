<template>
  <PageListLayout
    icon="mdi-home-city-outline"
    title="CHI NHÁNH NGÂN HÀNG"
    subTitle="Danh sách chi nhánh ngân hàng"
    useAdd
    titleAdd="Thêm chi nhánh ngân hàng"
    @add="createItem"
  >
    <template slot="filter">
      <v-text-field
        append-icon="mdi-magnify"
        v-model="search"
        label="Tìm kiếm"
        clearable
        single-line
        outlined
        dense
        hide-details
      ></v-text-field>
    </template>
    <CustomTable
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      :pageCount="pageCount"
      @change-page="changePage"
      classPaging="pt-2"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <!-- <v-list-item @click="print(item)">
                <v-list-item-title>
                  <v-icon class="mr-2"> mdi-printer </v-icon>
                  In yêu cầu báo giá</v-list-item-title
                >
              </v-list-item> -->
            <v-list-item @click="goEdit(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Cập nhật</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="deleteItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Xóa chi nhánh</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </CustomTable>
    <create-edit ref="form" @on-done="getAllList"></create-edit>
  </PageListLayout>
</template>
<script>
import { debounce } from "lodash";
import CreateEdit from "./create";
import { index, destroy } from "@/api/chinhanhnganhang";
export default {
  components: { CreateEdit },
  data() {
    return {
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      tableData: [],
      customsAreaList: [],
      btnLoading: false,
      menu: {},
      loading: false,
      search: "",
      headers: [
        { text: "Mã HQ", value: "ma_hq" },
        { text: "SKU", value: "sku", align: "start" },
        { text: "Đơn vị", value: "don_vi", align: "start" },
        { text: "Kho bạc", value: "kho_bac", align: "start" },
        { text: "Tỉnh", value: "tinh", align: "start" },
        {
          text: "Hành động",
          value: "action",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
    };
  },
  computed: {},
  created() {
    this.getAllList();
  },
  watch: {
    search: debounce(async function (val) {
      this.getAllList({ search: val });
    }, 300),
  },
  methods: {
    async getAllCustomsAreaList() {},
    async getAllList(params = {}) {
      this.loading = true;
      try {
        const res = await index({
          page: this.page,
          perPage: this.itemsPerPage,
          ...params,
        });
        this.tableData = res.data.data;
        this.pageCount = res.data.last_page;
      } finally {
        this.loading = false;
      }
    },
    changePage(val) {
      this.page = val;
      this.getAllList();
    },
    editItem(item) {
      this.$refs.form.showFormEdit(item);
    },
    async deleteItem(item) {
      this.$confirmBox.show({
        title: "Xóa chi nhánh",
        width: 500,
        body: "Bạn có chắc chắn muốn xóa chi nhánh",
        action: async () => await destroy(item.id),
        onDone: () => this.getAllList(),
      });
    },
    getSourceName(list, id) {
      return list.find((item) => item.value === id)
        ? list.find((item) => item.value === id).name
        : null;
    },
    createItem() {
      // this.$router.push({ path: "/tokhai/chinhsuatokhaihaiquan" });
      this.$refs.form.showFormAdd();
    },
    goEdit(item) {
      // this.$router.push({ path: `/tokhai/chinhsuatokhaihaiquan/${item.id}` });
      this.$refs.form.showFormEdit(item);
    },
    formatnumber(number) {
      return number.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    getBackgroundOutFlow(date) {
      const today = new Date();
      const flowOutDate = new Date(date);

      const difference = Math.abs(today - flowOutDate);
      let days;
      days = difference / (1000 * 3600 * 24);

      if (days < 5) {
        return "#ffffff";
      } else if (days >= 5 && days <= 10) {
        return "#ffdf9a";
      } else if (days > 10 && days <= 15) {
        return "red";
      } else if (days > 15) {
        return "#fffdf3";
      }
    },
    getBackgroundInFlow(date) {
      const today = new Date();
      const flowInDate = new Date(date);

      const difference = Math.abs(today - flowInDate);
      let days;
      days = difference / (1000 * 3600 * 24);

      if (days < 5) {
        return "#ffffff";
      } else if (days >= 5 && days <= 10) {
        return "#ffdf9a";
      } else if (days > 10 && days <= 15) {
        return "red";
      } else if (days > 15) {
        return "#fffdf3";
      }
    },
  },
};
</script>
