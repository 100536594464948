<template>
  <v-dialog v-model="show" persistent width="600">
    <v-card>
      <v-card-title class="headline">{{
        edit ? "CẬP NHẬT CHI NHÁNH NGÂN HÀNG" : "THÊM CHI NHÁNH NGÂN HÀNG"
      }}</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <div class="label-form">Tên viết tắt</div>
              <v-text-field
                v-model="form.sku"
                placeholder="Tên viết tắt"
                outlined
                dense
                prepend-inner-icon="mdi-code-tags"
                :disabled="edit ? true : false"
                :rules="required"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Mã hải quan</div>
              <v-text-field
                v-model="form.ma_hq"
                placeholder="Mã hải quan"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="label-form">Đơn vị</div>
              <v-text-field
                v-model="form.don_vi"
                placeholder="Nhập tên đơn vị hải quan"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
                :rules="required"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="label-form">Kho bạc</div>
              <v-text-field
                v-model="form.kho_bac"
                placeholder="Nhập thông tin kho bạc"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
                :rules="required"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="label-form">Tính</div>
              <v-text-field
                v-model="form.tinh"
                placeholder="Tỉnh"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
                :rules="required"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
        <v-btn
          :loading="btnLoading"
          color="primary"
          @click="addItem"
          v-if="!edit"
        >
          <v-icon left>mdi-plus</v-icon>
          Thêm mới
        </v-btn>
        <v-btn :loading="btnLoading" color="primary" @click="editItem" v-else>
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {create, update} from '@/api/chinhanhnganhang'
export default {
  data: () => ({
    show: false,
    edit: false,
    btnLoading: false,
    form: {
    },
    nameRules: [(v) => !!v || "Tên không thể bỏ trống"],
    required: [(v) => !!v || "Không thể bỏ trống"],
    codeRules: [(v) => !!v || "Mã không thể bỏ trống"],
  }),
  computed: {},
  methods: {
    showFormAdd() {
      this.show = true;
      this.edit = false;
      this.form = {

      };
    },
    async showFormEdit(data) {
      this.edit = true;
      this.show = true;
      this.form = { ...data };
    },
    async addItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          this.show = false;
          this.btnLoading = false;
          await create(this.form)
          this.$emit("on-done");
          this.$toast.info("Thêm thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
    async editItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          this.show = false;
          this.btnLoading = false;
          await update(this.form.id, this.form)
          this.$emit("on-done");
          this.$toast.info("Cập nhật thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
  },
};
</script>
